global.toastr = require("toastr")
// require("@rails/ujs").start()
import Rails from "@rails/ujs"
Rails.start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("channels")

import 'jquery'
import 'popper.js'
import 'bootstrap'

window.jQuery = $;
window.$ = $;

require("channels/admin/toastr.min")
require("channels/admin/moment.min")
require("channels/admin/adminlte")
require("channels/admin/select2.full.min")
require("channels/admin/bootstrap.bundle.min")
require("channels/admin/chart.min")
require("channels/admin/flatpickr")
require("channels/admin/jquery.dataTables.min")
require("channels/admin/dataTables.bootstrap4.min")

require("trix")
require("@rails/actiontext")
require("moment")

// require("flatpickr")
// import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  console.log("turbolinks loaded ")
  $('[data-toggle="tooltip"]').tooltip(),
  flatpickr("[class='flatpickr']", {})
});

require("../stylesheets/admin.scss")

$(document).on("turbolinks:load", () => {
  $('table').wrap("<div class='table-responsive'></div>");
  $("#contents").sortable({
    handle: '.handle',
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });
  $('.search-section input').val("");
});